/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import clsx from 'clsx';

import HeaderStyled from '@/components/styled/HeaderStyled';
import { useContext } from '@/components/app/context';
import { BaseComponentProps } from '@/types';
import Reveal from '@/components/reveal/Reveal';

interface SidebarQueryProps {
  contentfulMenu: {
    id: string;
    image: {
      gatsbyImageData: ImageDataLike;
    };
    imageAlt: string;
  };
}

const query = graphql`
  query sidebarQuery {
    contentfulMenu {
      id
      image {
        gatsbyImageData(width: 625, height: 528)
      }
      imageAlt
    }
  }
`;

const sidebar: React.FC<BaseComponentProps> = () => {
  const { contentfulMenu: data }: SidebarQueryProps = useStaticQuery(query);
  const { pathname } = useLocation();
  const [ready, setReady] = useState<boolean>(false);
  const [
    {
      header: { focused: headerFocused },
      menu: { visible: isVisible },
    },
    dispatch,
  ] = useContext();
  const initialFocusRef = useRef<any>();
  const onToggle = useCallback(
    (payload?: boolean) => {
      dispatch({
        type: `ToggleMenuVisibility`,
        payload,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 400);
  }, []);

  useEffect(() => {
    // If route changed, we close menu
    onToggle(false);
  }, [onToggle, pathname]);

  return (
    <HeaderStyled>
      <div className={clsx(`btn-container`, { show: ready && !headerFocused })}>
        <button
          type="button"
          onClick={() =>
            dispatch({
              type: `ToggleMenuVisibility`,
              payload: true,
            })
          }
          className="btn menu open"
        >
          menu
        </button>
      </div>
      <div id="menu-sidebar" className={`${isVisible ? `show` : `hide`}`}>
        <div className="menu-sidebar-header alt">
          <div className="container">
            <Link className="logo sidebar-logo" to="/">
              <img
                src="/assets/images/logo/iona-alt.svg"
                width="173"
                height="54"
                alt="IONA logo"
                className="i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
              />
            </Link>

            <Reveal className="social-media headline-container">
              <div className="linkedin">
                <a
                  tabIndex={0}
                  className="link alt"
                  href="https://www.linkedin.com/company/ionaco"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </div>

              <div className="instagram">
                <a
                  tabIndex={0}
                  className="link alt"
                  href="https://www.instagram.com/ionacompany/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </div>
            </Reveal>

            <button
              type="button"
              tabIndex={0}
              onClick={() => onToggle(false)}
              className="btn menu alt close"
            >
              close
            </button>
          </div>
        </div>
        <div className="menu-sidebar-nav container">
          <div className="row">
            <Reveal className="col-2 menu-sidebar-nav-image">
              <div className="menu-image-container">
                <GatsbyImage
                  image={getImage(data.image.gatsbyImageData)}
                  alt=""
                />
              </div>
            </Reveal>

            <Reveal className="col-2 col-m-2 menu-sidebar-nav-items">
              <nav className="menu-sidebar-nav-items-container">
                <ul className="menu-sidebar-nav-items-block-1">
                  <li>
                    <Link ref={initialFocusRef} tabIndex={0} to="/work/">
                      <span>Work</span>
                    </Link>
                  </li>
                  <li>
                    <Link tabIndex={0} to="/services/">
                      <span>Services</span>
                    </Link>
                  </li>
                  <li>
                    <Link tabIndex={0} to="/about/">
                      <span>About</span>
                    </Link>
                  </li>
                </ul>

                <ul className="menu-sidebar-nav-items-block-2">
                  <li>
                    <Link tabIndex={0} to="/careers/">
                      <span>Careers</span>
                    </Link>
                  </li>
                  <li>
                    <Link tabIndex={0} to="/insights/">
                      <span>Insights</span>
                    </Link>
                  </li>
                  <li>
                    <Link tabIndex={0} to="/contacts/">
                      <span>Contact</span>
                    </Link>
                  </li>
                </ul>
              </nav>
            </Reveal>
          </div>
        </div>
        <div className="menu-sidebar-footer">
          <Reveal className="container">
            <button
              type="button"
              className="btn menu alt close"
              onClick={() => onToggle(false)}
            >
              close
            </button>
          </Reveal>
        </div>
      </div>
    </HeaderStyled>
  );
};

export default sidebar;
